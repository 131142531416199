import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  StyledCustomSelect,
  StyledCustomSelectTrigger,
  StyledCustomSelectPlaceholder,
  StyledCustomSelectContent,
  StyledCustomSelectList,
  StyledCustomSelectItem,
  StyledCustomSelectLink,
  StyledCustomSelectIcon,
} from './styles'
import LocationIcon from '../../Icons/LocationIcon'

const CustomSelect = ({ options, placeholder }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      setIsCollapsed(!isCollapsed)
    },
    [isCollapsed],
  )

  return (
    <StyledCustomSelect isCollapsed={isCollapsed}>
      <StyledCustomSelectTrigger
        aria-label="Open accordion"
        onClick={handleClick}
      >
        <StyledCustomSelectPlaceholder>
          {placeholder}
        </StyledCustomSelectPlaceholder>
        <StyledCustomSelectIcon>
          <LocationIcon />
        </StyledCustomSelectIcon>
      </StyledCustomSelectTrigger>
      <StyledCustomSelectContent>
        <StyledCustomSelectList>
          {options.map(({ url, label }) => (
            <StyledCustomSelectItem key={label}>
              <StyledCustomSelectLink to={url}>{label}</StyledCustomSelectLink>
            </StyledCustomSelectItem>
          ))}
        </StyledCustomSelectList>
      </StyledCustomSelectContent>
    </StyledCustomSelect>
  )
}

export default CustomSelect

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
}
