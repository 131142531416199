import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import CustomSelect from '../components/Field/CustomSelect/CustomSelect'
import ModelsList from '../components/ModelsList/ModelsList'
import { Heading2, Heading7 } from '../components/Typography/Typography'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import InfoBox from '../components/InfoBox/InfoBox'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import { URL_PATHS } from '../constants/urlPaths'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import { bgTheme, replaceAtToSpan, generateHero } from '../common/utils'
import useSaveModels from '../common/hooks/useSaveModels'
import HTMLBody from '../components/HTMLBody'
import { useVipModels } from '../common/hooks/useVipModels'

const ModelsBody = ({ data }) => {
  const { edges: cities } = data.wp.cities
  const { edges: models } = data.wp.models
  const page = data.wp.page?.modelsAcf
  const contentBlocks = data.wp.page?.acf.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const ourModelsBg = bgTheme(page.modelsListBg)

  const citiesOptions = cities
    .filter(
      ({
        node: {
          acf: { showInSelectors },
        },
      }) => showInSelectors,
    )
    .map(({ node: { name, slug } }) => ({
      label: name,
      url: `${URL_PATHS.MODELS}/${slug}`,
    }))
  
  const modelsListDefault = useSaveModels(models, 'all')

  const { modelsList } = useVipModels(modelsListDefault)

  return (
    <>
      <Container
        bg={[heroBgColorMobile, heroBgColorDesktop]}
        color={[heroColorMobile, heroColorDesktop]}
      >
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroSlantType={slantDirection || 'right'}
          gradient={hasBgGradient ? bgGradient : null}
          centered
          long={isHeroHigh}
          {...{ heroImages }}
        >
          <Container mt={['-8rem', '', '0']}>
            <InfoBox
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(page.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Container>
        </TruncatedHero>
      </Container>
      <Container pt={['', '3.125rem']} pb={['2.5rem', '', '']} bg={ourModelsBg}>
        <Inner>
          <Container
            display="flex"
            alignItems={['', 'center']}
            justifyContent="space-between"
            flexDirection={['column', 'row']}
            zIndex="2"
            position="relative"
            mb="2rem"
            className="uiAnimCustomBottom"
          >
            <Container mb={['1.25rem', '0']}>
              <Heading7
                color="yellow"
                as="h2"
                fontSize={['1.875rem', '2.25rem']}
              >
                {page?.modelsListHeading !== null
                  ? page.modelsListHeading
                  : 'Our Models'}
              </Heading7>
            </Container>
            <Container display="flex" flexShrink="0">
              <CustomSelect
                options={citiesOptions}
                placeholder="Choose Location"
              />
            </Container>
          </Container>
          <Container className="uiAnimCustomBottom">
            <ModelsList models={modelsList} anim={false} />
          </Container>
        </Inner>
      </Container>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
    </>
  )
}

const ModelsPage = ({ data }) => {
  const seoData = data.wp.page?.seo

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <ModelsBody data={data} />
    </Layout>
  )
}

export default ModelsPage

export const query = graphql`
  query {
    wp {
      cities(first: 100) {
        edges {
          node {
            id
            name
            slug
            acf {
              showInSelectors
            }
          }
        }
      }
      models(first: 50) {
        edges {
          node {
            id
            slug
            title
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(sizes: "(max-width: 767px) calc(100vw - 60px), 555px") {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              ...modelDetailsFragment
              comingSoon
              imageToShowOnListing {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    fluid(
                      sizes: "(max-width: 767px) calc(100vw - 60px), 555px"
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            termSlugs(taxonomies: CITY)
          }
        }
      }
      page(id: "models", idType: URI) {
        id
        slug
        title
        modelsAcf {
          modelsListHeading
          modelsListBg
          heroFields {
            infoboxTitle
            infoboxContent
            slantDirection
            bgGradient
            boxBackgroundOpacity
            boxBackgroundColor
            heroHeight
            bgDesktop
            bgMobile
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        acf {
          contentBlocks {
            content {
              ... on wp_Page_Acf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Acf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Acf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
              }
            }
          }
        }
        seo {
          ...seoFragment
        }
      }
    }
  }
`
