import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import { transition } from '../../../common/mixins'
import media from '../../../common/MediaQueries'

const StyledCustomSelectTrigger = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 2.625rem;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.darkGrey};
  z-index: 2;
  cursor: pointer;
`

const StyledCustomSelectPlaceholder = styled.span``

const StyledCustomSelectIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
`

const StyledCustomSelectContent = styled.div`
  display: none;
  visibility: hidden;
  position: absolute;
  top: calc(100% - 0.0625rem);
  left: -0.0625rem;
  right: -0.0625rem;
  width: calc(100% + 0.125rem);
  border: 1px solid ${({ theme }) => theme.colors.yellow};
`

const StyledCustomSelectList = styled.ul``

const StyledCustomSelectItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid #222;
  }
`

const StyledCustomSelectLink = styled(Link)`
  display: flex;
  padding: 0.5rem 0.75rem;
  background: ${({ theme }) => theme.colors.black};
  -webkit-tap-highlight-color: initial;
  cursor: pointer;
  ${transition}

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledCustomSelect = styled.div`
  position: relative;
  width: 100%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.yellow};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  background: ${({ theme }) => theme.colors.black};

  @media ${media.phone} {
    min-width: 12.25rem;
  }

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      ${StyledCustomSelectContent} {
        display: block;
        z-index: 1;
        visibility: visible;
      }
    `}
`

export {
  StyledCustomSelect,
  StyledCustomSelectPlaceholder,
  StyledCustomSelectTrigger,
  StyledCustomSelectContent,
  StyledCustomSelectList,
  StyledCustomSelectItem,
  StyledCustomSelectLink,
  StyledCustomSelectIcon,
}
